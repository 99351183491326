<section
  class="show-surveys full-height"
  id="show-surveys-page"
  (window:resize)="onWindowResize($event.target)"
>
  <!-- #region quickview panel -->
  <div
    class="quickview-wrapper customizer overflow-hidden"
    id="quickview-survey"
    [ngClass]="{ 'quickview-open': surveyClicked }"
  >
    <div class="w-100 h-100 d-flex flex-column">
      <div class="w-100 px-2 d-flex gap-2 justify-content-between align-items-center">
        <button
          mat-mini-fab
          color="none"
          (click)="handleSurveyClicked(userID, selectedSurvey)"
        >
          <mat-icon class="notranslate">close</mat-icon>
        </button>
        <div class="mx-auto">{{ surveyClickedName }}</div>
        <button mat-mini-fab color="none" (click)="zoomOut()">
          <mat-icon class="notranslate">zoom_out</mat-icon>
        </button>
        <button mat-mini-fab color="none" (click)="zoomIn()">
          <mat-icon class="notranslate">zoom_in</mat-icon>
        </button>
      </div>
      <div
        #quickView
        class="mt-5 quickview-survey-inner overflow-x-auto"
        [style.zoom]="zoomMode + '%'"
      >
        <div *ngIf="surveyClicked" class="previewSurveyContainer">
          <launch-survey [quickView]="quickViewObj"></launch-survey>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion quickview panel -->

  <div class="h-100 d-flex flex-column">
    <!-- #region header toolbar -->
    <show-surveys-toolbar
      [(searchValue)]="searchValue"
      [(selectedTab)]="selectedTab"
      (newFolder)="newFolder()"
      (createNewSurvey)="createNewSurvey()"
      [selectedFolder]="selectedFolder"
      (previousFolder)="goToParentFolder()"
    >
    </show-surveys-toolbar>
    <!-- #endregion header tooldbar -->

    <div class="d-flex flex-md-row flex-column flex">
      <!-- #region main content -->
      <div class="flex d-flex align-items-stretch tab-content surveyListHeight">
        <!-- #region tablet size navbar -->
        <div
          id="settingsSideNav"
          class="span1 primary-navbar hidden-sm-down tablet-menu"
        >
          <show-surveys-navigation
            [mobile]="false"
            [(selectedTab)]="selectedTab"
            (resetVariables)="resetVariables()"
          >
          </show-surveys-navigation>
        </div>
        <!-- #endregion tablet size navbar -->

        <!-- #region main content -->
        <div
          class="d-flex flex-column h-100 w-100 overflow-hidden justify-content-start"
        >
          <!-- #region tab contents -->
          <div id="settingsTabContent" class="tab-content overflow-x-auto">
            <!-- #region loader -->
            <div *ngIf="loadingSurveys || !companyID" class="w-100 h-100">
              <insig-app-loader></insig-app-loader>
            </div>
            <!-- #endregion loader -->

            <!-- #region my questionnaires tab -->
            <div
              cdkDropListGroup
              class="tab-content full-height px-2 w-100 drag-and-drop-boundary"
              [hidden]="selectedTab !== 'user'"
            >
              <!-- #region user folders -->
              <div
                class="primary-color-full py-5 text-center"
                [ngClass]="{
                  'hidden-drop-list': !showDragHome || !selectedFolder
                }"
                cdkDropList
                [cdkDropListDisabled]="true"
                [cdkDropListSortingDisabled]="true"
                (cdkDropListDropped)="putSurveyHome($event)"
              >
                <h4>Drag Here to Place Survey in Home Directory</h4>
              </div>

              <ng-container *ngIf="userFolders$ | async as userFolders">
                <ng-container *ngIf="userFolders.length > 0">
                  <div class="container m-0 w-100">
                    <div class="h5">Folders</div>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-6 col-6 valid-droplist"
                        *ngFor="let folder of userFolders"
                        cdkDropList
                        [cdkDropListDisabled]="true"
                        [cdkDropListSortingDisabled]="true"
                        (cdkDropListDropped)="onSurveyDrop($event)"
                        [cdkDropData]="folder"
                        (click)="goToFolder(folder)"
                        [hidden]="
                          (searchValue
                            ? folder.name
                                .toLowerCase()
                                .indexOf(searchValue.toLowerCase()) === -1
                            : false) ||
                          (selectedFolder
                            ? folder.parent !== selectedFolder.id
                            : folder.parent)
                        "
                      >
                        <div class="my-2 card bg-color-white">
                          <button mat-button class="justify-content-start text-truncate">
                            <mat-icon class="notranslate">folder</mat-icon>
                            {{ folder.name }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                </ng-container>
              </ng-container>
              <!-- #endregion user folders -->

              <!-- #region user surveys -->
              <ng-container *ngIf="userSurveys">
                <div
                  *ngIf="!userSurveys || userSurveys.length == 0"
                  class="text-center"
                >
                  <h4>You currently have no questionnaires!</h4>
                  <h4>Click 'New' above to create one</h4>
                </div>

                <div class="container m-0 w-100">
                  <div
                    cdkDropList
                    [cdkDropListDisabled]="isDraggingDisabled"
                    [cdkDropListSortingDisabled]="true"
                    (cdkDropListDropped)="showDragHome = false"
                    class="row pb-5"
                  >
                    <div
                      class="col-xl-4 col-lg-6"
                      *ngFor="let survey of userSurveys"
                      cdkDrag
                      onmousedown="return false"
                      [cdkDragData]="survey"
                      (cdkDragStarted)="showDragHome = true"
                      [hidden]="
                        (searchValue
                          ? survey.name
                              .toLowerCase()
                              .indexOf(searchValue.toLowerCase()) === -1
                          : false) ||
                        ((selectedFolder
                          ? survey.folder !== selectedFolder.id
                          : survey.folder) &&
                          !searchValue)
                      "
                    >
                      <div class="hide-placeholder" *cdkDragPlaceholder></div>
                      <div
                        class="card bg-color-white"
                        [ngClass]="{
                          'primary-color-full':
                            !!selectedSurvey && selectedSurvey.id === survey.id
                        }"
                      >
                        <button
                          insTooltip="{{ survey.name }}"
                          insTooltipPosition="above"
                          mat-button
                          (click)="handleSurveyClicked(userID, survey)"
                        >
                          <a class="customizer-toggle" href="javascript:;">
                            <div class="overflow-x-auto">
                              <span
                                *ngIf="survey.feedbackEnabled"
                                insTooltip="Feedback Enabled"
                              >
                                <img
                                  src="{{
                                    'assets/images/logo/white-logo.png'
                                  }}"
                                  class="mr-2"
                                  style="height:30px;"
                                />
                              </span>
                              <span class="card-title">{{ survey.name }}</span>
                            </div>
                          </a>
                        </button>
                        <div class="card-action d-flex">
                          <button
                            mat-icon-button
                            class="mr-auto"
                            insTooltip="Send Questionnaire"
                            insTooltipPosition="above"
                            (click)="
                              sendSurvey(survey.id, 'user');
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate">send</mat-icon>
                          </button>

                          <button
                            id="edit-user-survey-{{ survey.id }}"
                            mat-icon-button
                            insTooltip="Edit Questionnaire"
                            insTooltipPosition="above"
                            (click)="
                              editSurvey(companyID, userID, survey.id);
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate">create</mat-icon>
                          </button>
                          <button
                            mat-icon-button
                            insTooltip="Patient Preview"
                            insTooltipPosition="above"
                            (click)="
                              launchSurvey('user', companyID, userID, survey);
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate"
                              >laptop_chromebook</mat-icon
                            >
                          </button>
                          <button
                            id="more-user-survey-options-{{ survey.id }}"
                            mat-icon-button
                            class="small-button"
                            insTooltip="More"
                            insTooltipPosition="above"
                            [matMenuTriggerFor]="surveyMoreMenu"
                            [matMenuTriggerData]="{
                              survey: survey,
                              type: 'user'
                            }"
                            (click)="$event.stopPropagation()"
                          >
                            <mat-icon class="notranslate">more_vert</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- #endregion user surveys -->
            </div>
            <!-- #endregion my questionnaires tab -->

            <!-- #region library tab -->
            <div
              class="tab-content full-height px-2 w-100"
              [hidden]="selectedTab !== 'library'"
            >
              <ng-container *ngIf="libraryFolders$ | async as libraryFolders">
                <ng-container *ngIf="libraryFolders.length > 0">
                  <div class="container m-0 w-100">
                    <div class="h5">Library Folders</div>
                    <div class="row">
                      <div
                        class="col-xl-4 col-lg-6 col-6"
                        *ngFor="let folder of libraryFolders"
                        (click)="goToFolder(folder, true)"
                        [hidden]="
                          (searchValue
                            ? folder.name
                                .toLowerCase()
                                .indexOf(searchValue.toLowerCase()) === -1
                            : false) ||
                          (selectedFolder
                            ? folder.parent !== selectedFolder.id
                            : folder.parent)
                        "
                      >
                        <div class="my-2 card bg-color-white">
                          <button mat-button class="justify-content-start text-truncate">
                            <mat-icon class="notranslate">folder</mat-icon>
                            {{ folder.name }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </ng-container>
              </ng-container>

              <ng-container *ngIf="librarySurveys$ | async as librarySurveys">
                <div class="container m-0 w-100">
                  <div class="row pb-5">
                    <div
                      *ngFor="let survey of librarySurveys"
                      onmousedown="return false"
                      class="col-xl-4 col-lg-6"
                      [hidden]="
                        (searchValue
                          ? survey.name
                              .toLowerCase()
                              .indexOf(searchValue.toLowerCase()) === -1
                          : false) ||
                        ((selectedFolder
                          ? survey.folder !== selectedFolder.id
                          : false) &&
                          !searchValue)
                      "
                    >
                      <div class="card bg-color-white">
                        <button
                          insTooltip="{{ survey.name }}"
                          class="m-2"
                          insTooltipPosition="above"
                          mat-button
                          (click)="handleSurveyClicked(null, survey)"
                        >
                          <a class="customizer-toggle" href="javascript:;">
                            <div class="overflow-x-auto">
                              <span class="card-title">{{ survey.name }}</span>
                            </div>
                          </a>
                        </button>
                        <div class="card-action d-flex">
                          <button
                            mat-icon-button
                            insTooltip="Send Questionnaire"
                            class="mr-auto small-button"
                            insTooltipPosition="above"
                            (click)="
                              sendSurvey(survey.id, 'library');
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate">send</mat-icon>
                          </button>

                          <button
                            mat-icon-button
                            class="small-button"
                            insTooltip="Copy to Your Questionnaires"
                            insTooltipPosition="above"
                            (click)="
                              copyToYourSurveys(survey.id);
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate"
                              >content_copy</mat-icon
                            >
                          </button>
                          <button
                            id="patient-preview-library-survey-{{ survey.id }}"
                            mat-icon-button
                            class="small-button"
                            insTooltip="Patient Preview"
                            insTooltipPosition="above"
                            (click)="
                              launchSurvey(
                                'library',
                                companyID,
                                'library',
                                survey
                              );
                              $event.stopPropagation()
                            "
                          >
                            <mat-icon class="notranslate"
                              >laptop_chromebook</mat-icon
                            >
                          </button>
                          <button
                            id="more-user-survey-options-{{ survey.id }}"
                            mat-icon-button
                            class="small-button"
                            insTooltip="More"
                            insTooltipPosition="above"
                            [matMenuTriggerFor]="surveyMoreMenu"
                            [matMenuTriggerData]="{
                              survey: survey,
                              type: 'library'
                            }"
                            (click)="$event.stopPropagation()"
                          >
                            <mat-icon class="notranslate">more_vert</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- #endregion library tab -->

            <!-- #region organization tab -->
            <div
              class="tab-content full-height px-2 w-100"
              [hidden]="selectedTab !== 'organization'"
            >
              <div class="container m-0 w-100">
                <div class="box-body text-center" *ngIf="!selectedDoctor">
                  <h4 class="my-5">
                    Select a practitioner to view their questionnaires
                  </h4>
                  <button
                    mat-raised-button
                    class="primary-color-full btn-w-lg btn-lg"
                    (click)="changeDoctor()"
                  >
                    Select a Practitioner
                  </button>
                </div>

                <div class="row" *ngIf="selectedDoctor">
                  <div class="box-body w-100">
                    <button
                      mat-raised-button
                      class="primary-color-full float-right"
                      (click)="changeDoctor()"
                    >
                      Change Practitioner
                    </button>
                    <h4 class="mt-0">
                      {{
                        selectedDoctor
                          ? selectedDoctor.first + ' ' + selectedDoctor.last
                          : 'Practitioner'
                      }}'s Questionnaires
                    </h4>
                  </div>
                  <div
                    *ngFor="let survey of doctorSurveys"
                    onmousedown="return false"
                    class="col-xl-4 col-lg-6"
                    [hidden]="
                      searchValue
                        ? survey.name
                            .toLowerCase()
                            .indexOf(searchValue.toLowerCase()) === -1
                        : false
                    "
                  >
                    <div class="card bg-color-white">
                      <button
                        insTooltip="{{ survey.name }}"
                        class="m-2"
                        insTooltipPosition="above"
                        mat-button
                        (click)="
                          handleSurveyClicked(selectedDoctor.uid, survey)
                        "
                      >
                        <a class="customizer-toggle" href="javascript:;">
                          <div class="overflow-x-auto">
                            <span class="card-title">{{ survey.name }}</span>
                          </div>
                        </a>
                      </button>

                      <div class="card-action d-flex">
                        <button
                          mat-icon-button
                          insTooltip="Send Questionnaire"
                          class="mr-auto"
                          insTooltipPosition="above"
                          (click)="
                            sendSurvey(survey.id, 'organization');
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon class="notranslate">send</mat-icon>
                        </button>

                        <button
                          mat-icon-button
                          insTooltip="Copy to Your Questionnaires"
                          insTooltipPosition="above"
                          (click)="
                            copyOrgToYourSurveys(survey.id);
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon class="notranslate">content_copy</mat-icon>
                        </button>
                        <button
                          id="patient-preview-library-survey-{{ survey.id }}"
                          mat-icon-button
                          insTooltip="Patient Preview"
                          insTooltipPosition="above"
                          (click)="
                            launchSurvey(
                              'organization',
                              companyID,
                              selectedDoctor.uid,
                              survey
                            );
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon class="notranslate">laptop_chromebook</mat-icon>
                        </button>
                        <button
                          id="more-user-survey-options-{{ survey.id }}"
                          mat-icon-button
                          insTooltip="More"
                          insTooltipPosition="above"
                          [matMenuTriggerFor]="surveyMoreMenu"
                          [matMenuTriggerData]="{
                            survey: survey,
                            type: 'organization'
                          }"
                          (click)="$event.stopPropagation()"
                        >
                          <mat-icon class="notranslate">more_vert</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #endregion organization tab -->

            <div
              class="tab-content full-height px-2 w-100"
              [hidden]="selectedTab !== 'checkin'"
            >
              <show-surveys-check-in-mode
                (startCheckInMode)="startCheckInMode($event)"
              ></show-surveys-check-in-mode>
            </div>
          </div>
          <!-- #endregion tab contents -->

          <!-- #region mobile size navbar -->
          <div class="horizontal-nav w-100 hidden-lg-up mt-auto">
            <show-surveys-navigation
              class="full-width"
              [mobile]="true"
              [(selectedTab)]="selectedTab"
              (resetVariables)="resetVariables()"
              [companyData]="companyData"
            ></show-surveys-navigation>
          </div>
          <!-- #endregion mobile size navbar -->
        </div>
        <!-- #endregion main content -->
      </div>
      <!-- #endregion main content -->

      <!-- #region sidenav component -->
      <show-surveys-sidenav
        [hidden]="!showSideNav"
        [(selectedTab)]="selectedTab"
        (resetVariables)="resetVariables()"
        [(selectedFolder)]="selectedFolder"
        [selectedSurvey]="selectedSurvey"
        (onEditClicked)="editSurvey(companyID, userID, $event.id)"
        (onPatientPreviewClicked)="
          launchSurvey('user', companyID, userID, $event)
        "
        (onDeleteFolderConfirmed)="deleteFolder(selectedFolder)"
        [companyData]="companyData"
      >
      </show-surveys-sidenav>
      <!-- #endregion sidenav component -->
    </div>
  </div>
</section>

<mat-menu #surveyMoreMenu="matMenu">
  <ng-template matMenuContent let-survey="survey" let-type="type">
    <button mat-menu-item (click)="sendSurvey(survey.id, type)">
      <mat-icon class="notranslate">send</mat-icon>
      <span>Send Questionnaire</span>
    </button>

    <button
      *ngIf="type === 'user'"
      mat-menu-item
      (click)="editSurvey(companyID, userID, survey.id)"
    >
      <mat-icon class="notranslate">create</mat-icon>
      <span>Edit Questionnaire</span>
    </button>

    <button
      *ngIf="type === 'user'"
      mat-menu-item
      (click)="launchSurvey(type, companyID, userID, survey)"
    >
      <mat-icon class="notranslate">laptop_chromebook</mat-icon>
      <span>Patient Preview</span>
    </button>
    <button
      *ngIf="type === 'library'"
      mat-menu-item
      (click)="launchSurvey(type, companyID, 'library', survey)"
    >
      <mat-icon class="notranslate">laptop_chromebook</mat-icon>
      <span>Patient Preview</span>
    </button>
    <button
      *ngIf="type === 'organization'"
      mat-menu-item
      (click)="launchSurvey(type, companyID, selectedDoctor.uid, survey)"
    >
      <mat-icon class="notranslate">laptop_chromebook</mat-icon>
      <span>Patient Preview</span>
    </button>

    <button
      *ngIf="type === 'user'"
      mat-menu-item
      (click)="duplicateSurvey(survey.id)"
    >
      <mat-icon class="notranslate">content_copy</mat-icon>
      <span>Duplicate Questionnaire</span>
    </button>

    <button
      *ngIf="type === 'library'"
      mat-menu-item
      (click)="copyToYourSurveys(survey.id)"
    >
      <mat-icon class="notranslate">content_copy</mat-icon>
      <span>Copy To Your Questionnaires</span>
    </button>
    <button
      *ngIf="type === 'organization'"
      mat-menu-item
      (click)="copyOrgToYourSurveys(survey)"
    >
      <mat-icon class="notranslate">content_copy</mat-icon>
      <span>Copy To Your Questionnaires</span>
    </button>

    <button
      *ngIf="type === 'user'"
      mat-menu-item
      (click)="openMoveToFolderDialog(survey)"
    >
      <mat-icon class="notranslate">folder</mat-icon>
      <span>Move to Folder</span>
    </button>

    <button
      *ngIf="type === 'user'"
      id="delete-user-survey-{{ survey.id }}"
      mat-menu-item
      (click)="deleteSurveyConfirm(survey)"
    >
      <mat-icon class="notranslate">delete</mat-icon>
      <span>Delete Questionnaire</span>
    </button>
  </ng-template>
</mat-menu>
