// @ts-strict-ignore
// @angular
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

// global
import { APPURL, SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { DeleteSurveyService } from 'insig-app/services/deleteSurvey.service';
import { DuplicateSurveyService } from 'insig-app/services/duplicateSurvey.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { NewSurveyService } from 'insig-app/services/newSurvey.service';
import { SaveSurveyService } from 'insig-app/services/saveSurvey.service';
import { CompanyLocationService } from 'insig-app/services/company/location.service';
import { NotesService } from 'insig-app/services/notes.service';
import { SurveyFolder } from 'insig-types/surveys/folder';
// relative

import { SelectAppointmentSurveyDialogComponent } from 'insig-app/global/virtual/settings/dialogs/select-appointment-survey.component';
import { SelectDoctorDialogComponent } from '../dialogs/select-doctor-dialog.component';
import { SendSurveyDialogComponent } from '../dialogs/send-survey-dialog.component';
import { ConfirmPreviewDialogComponent } from '../dialogs/confirm-preview-dialog.component';
import { ConfirmDeleteSurveyDialogComponent } from '../dialogs/confirm-delete-survey-dialog.component';
import { NewFolderDialogComponent } from '../dialogs/new-folder-dialog/new-folder-dialog.component';
import { MoveToFolderDialogComponent } from '../dialogs/move-to-folder-dialog.component';
import { FoldersService } from '../services/folders.service';
import { SubmitSurveyService } from '../services/submit-survey.service';
import { InitNoteService } from 'insig-app/services/initNote.service';
// npm packages
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { Survey } from 'insig-types/surveys/survey';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'my-show-surveys',
  templateUrl: './show-surveys.component.html',
  providers: [
    SaveSurveyService,
    NewSurveyService,
    NotesService,
    SubmitSurveyService,
    LoadSurveyService,
    DeleteSurveyService,
    DuplicateSurveyService,
    FoldersService,
    CookieService,
    InitNoteService,
    CompanyLocationService,
  ],
  styleUrls: [
    '../../../styles/surveys/show-surveys.scss',
    '../../../styles/global/secondary-nav/main.scss',
    './show-surveys.component.scss',
  ],
})
export class ShowSurveysComponent implements OnInit, OnDestroy {
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyDataQuery($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        name
        companyUrl
        brandingName
        companyPlan
        checkIn
      }
    }
  `;

  private doctorListQuery = gql`
    query DoctorListQuery($companyID: ID!) {
      getCompanyUserList(cid: $companyID) {
        first
        last
        uid
        valid
        company
        acceptVirtual
      }
    }
  `;

  //

  public userSurveys: Survey[] = [];
  public librarySurveys = null;
  public userID = null;
  public previewedSurvey = null;
  public selectedDoctor = null;
  public doctorSurveys = [];
  public doctorList = null;
  ////////////////////////////////////////
  // this needs to come from the auth token
  public companyID = null;
  ////////////////////////////////////////
  private insigURL = APPURL + 'surveys/launch/user';
  private insigLibraryURL = APPURL + 'surveys/launch/library';
  public quickViewObj = {};
  public surveyClicked = false;
  public zoomMode = 60;
  public loadingSurveys = false;
  public companyData: any;

  private _selectedTab: string =
    this.cookieService.get('insig_showSurveysSelectedTab') || 'library';
  public set selectedTab(value: string) {
    this._selectedTab = value;
    this.searchValue = ''; // Reset search bar
    this.cookieService.set(
      'insig_showSurveysSelectedTab',
      value,
      undefined,
      undefined,
      undefined,
      false,
      'Lax'
    );
  }
  public get selectedTab(): string {
    return this._selectedTab;
  }

  public showSideNav = true;
  private showSideNavWidth = 993;
  public libraryFolders = [];
  public searchValue = '';
  public selectedFolder: any;
  public showDragHome = false;
  public isDraggingDisabled = false;
  public selectedSurvey: any;
  public surveyClickedName = '';

  public libraryFolders$ = this.foldersService
    .getLibraryFolders()
    .pipe(map((folders) => folders.sort(this.sortLibraryFunc)));
  public librarySurveys$ = this.loadSurveyService
    .getLibrarySurveyShortlistFromFirestore()
    .then((surveys) => surveys.sort((a, b) => a.name.localeCompare(b.name)));

  public user$ = this.firebaseAuthService.onIdTokenChanged().pipe(filter((user) => user !== null));
  public userId$ = this.user$.pipe(map((user) => user.uid));
  public userFolders$ = this.userId$.pipe(
    switchMap((uid) => this.foldersService.getUserFolders(uid)),
    map((folders) => folders.sort(this.sortByName))
  );

  // unsubscribes
  private userSubscription: Subscription;

  // endpoints

  constructor(
    private foldersService: FoldersService,
    public firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private deleteSurveyService: DeleteSurveyService,
    private loadSurveyService: LoadSurveyService,
    private newSurveyService: NewSurveyService,
    private duplicateSurveyService: DuplicateSurveyService,
    private cookieService: CookieService,
    public submitSurveyService: SubmitSurveyService,
    private saveSurveyService: SaveSurveyService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private companyLocationService: CompanyLocationService,
    private apollo: Apollo,
    private deviceService: DeviceDetectorService
  ) {} // end constructor

  ngOnInit(): void {
    this.showSideNav = window.innerWidth >= this.showSideNavWidth;
    this.isDraggingDisabled = this.checkIfDraggingDisabled();
    this.userSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.userID = user.uid;
          this.getSurveys(this.userID);
          try {
            const userDataQuery: any = await this.apollo
              .query({
                query: this.userDataQuery,
                variables: {
                  userID: user.uid,
                },
              })
              .toPromise();
            const userData = userDataQuery.data.getUserData;
            if (userData) {
              this.companyID = userData.company;
              this.getDoctorList(this.companyID);
              this.getCompanyData(this.companyID);
            }
          } catch (error) {
            switch (error.code) {
              default:
                console.log(error);
                break;
              case 'PERMISSION_DENIED':
                console.log('Wrong uid. User may have logged out.');
                break;
            }
          }
        } else {
          this.router.navigate(['/auth/login']);
        }
      }); // end auth service
    // exit tablet mode, mainly for dev purposes
    this.cookieService.set(
      'tabletMode',
      'false',
      undefined,
      undefined,
      undefined,
      true,
      'None'
    );
  } // end init

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  // get surveys for any userID
  async getSurveys(userID: string): Promise<void> {
    try {
      this.userSurveys = await firebase
        .firestore()
        .collection('userSurveys')
        .doc('surveyData')
        .collection(userID)
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs
            .map((doc) => doc.data() as Survey)
            .sort(this.sortByName)
        );
      this.loadingSurveys = false;
    } catch (error) {
      console.error(error);
      this.userSurveys = [];
      this.loadingSurveys = false;
    }
  } // end get surveys func

  sortByName = (a: { name: string }, b: { name: string }) => {
    return a.name.localeCompare(b.name);
  };

  /**
   * Prioritizes 'Insig' items, then sorts the rest by name
   */
  sortLibraryFunc = (a: { name: string }, b: { name: string }) => {
    // Sort by name
    if (b.name.includes('Insig')) {
      return 1;
    } else if (a.name.includes('Insig')) {
      return -1;
    }
    return a.name.localeCompare(b.name);
  };

  async startCheckInMode(type: string): Promise<boolean> {
    let checkInType;
    if (type !== 'display') {
      checkInType = 'kiosk';
    } else {
      checkInType = 'display';
    }
    console.log('in start check in function');
    if (!this.companyID) {
      console.log('Still loading company or userID');
      return false;
    }
    console.log('doctor list: ', this.doctorList);
    let companyLocations;
    try {
      companyLocations = await this.companyLocationService
        .getLocations(this.companyID)
        .pipe(take(1))
        .toPromise();
    } catch (err) {
      console.log('error getting company locations');
    }
    if (!!companyLocations && companyLocations.length > 0) {
      const dialogRef = this.dialog.open(
        SelectAppointmentSurveyDialogComponent
      );
      dialogRef.componentInstance.userList = this.doctorList;
      dialogRef.componentInstance.librarySurveys = this.librarySurveys;
      dialogRef.componentInstance.appointment = false;
      dialogRef.componentInstance.locations = companyLocations;
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log(result);
          // {survey: surveyDetails, location: this.selectedLocation}
          const survey = result.survey;
          const location = result.location;
          this.router.navigate([
            '/check-in/check-in-mode',
            checkInType,
            this.companyID,
            location.id,
            survey.userID,
            survey.surveyID,
          ]);
        }
      }); // end after closed
    } else {
      this.snackBar.open('Please add locations to your company!', null, {
        duration: 3000,
      });
    }
  }

  sendSurvey(
    surveyID: string,
    type: 'user' | 'organization' | 'library'
  ): void {
    const dialogRef = this.dialog.open(SendSurveyDialogComponent);
    dialogRef.componentInstance.surveyID = surveyID;
    dialogRef.componentInstance.userID = this.userID;
    dialogRef.componentInstance.companyID = this.companyID;
    switch (type) {
      case 'user':
        dialogRef.componentInstance.insigURL = this.insigURL;
        break;
      case 'library':
        dialogRef.componentInstance.insigURL = this.insigLibraryURL;
        break;
      case 'organization':
        dialogRef.componentInstance.insigURL = this.insigURL;
        dialogRef.componentInstance.userID = this.selectedDoctor.uid;
        break;
    }
  } // end send survey func

  async launchSurvey(
    type: 'user' | 'organization' | 'library',
    companyID: string,
    userID: string,
    survey: any
  ): Promise<void> {
    if (!companyID || !userID) {
      console.log('Still loading company or userID');
      this.snackBar.open('Still loading... please wait', null, {
        duration: 3000,
      });
      return;
    }

    return this.dialog
      .open(ConfirmPreviewDialogComponent)
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          if (type === 'user') {
            this.router.navigate([
              '/app/surveys/launch',
              'preview',
              'user',
              companyID,
              userID,
              survey.id,
            ]);
          } else if (type === 'library') {
            this.router.navigate([
              '/app/surveys/launch/',
              'preview',
              'library',
              companyID,
              'library',
              survey.id,
            ]);
          } else if (type === 'organization') {
            this.router.navigate([
              '/app/surveys/launch/',
              'preview',
              'user',
              companyID,
              this.selectedDoctor.uid,
              survey.id,
            ]);
          } else {
            console.log('Error, must be user or library or org survey type');
          }
        }
      });
  } // end launcsurvey func

  async editSurvey(
    companyId: string,
    userId: string,
    surveyId: string
  ): Promise<boolean> {
    if (!companyId || !userId) {
      console.log('Still loading company or userID');
      this.snackBar.open('Still loading... please wait', null, {
        duration: 3000,
      });
      return false;
    }
    return this.router.navigate([
      '/app/surveys/edit-survey',
      companyId,
      userId,
      surveyId,
    ]);
  } // end editsurvey func

  zoomIn(): void {
    this.zoomMode = this.zoomMode >= 100 ? 100 : this.zoomMode + 10;
  }

  zoomOut(): void {
    this.zoomMode = this.zoomMode <= 50 ? 50 : this.zoomMode - 10;
  }

  handleSurveyClicked(userId: string, survey: Survey): void {
    this.surveyClicked = false;

    if (!!this.selectedSurvey && this.selectedSurvey.id === survey.id) {
      delete this.selectedSurvey;
      delete this.surveyClickedName;
    } else {
      this.selectedSurvey = survey;
      this.surveyClickedName = survey.name;
      this.createQuickViewObject(userId, survey);
      setTimeout(() => {
        this.surveyClicked = true;
      }, 0);
    }
  }

  createQuickViewObject(userID: string, survey: Survey): void {
    const surveyID = survey.id;
    // creates object to be passed to launch-survey to have a quickview of the survey
    let type = null;
    if (userID) {
      type = 'user';
    } else {
      type = 'library';
    }
    this.quickViewObj = { type, userID, surveyID, companyID: this.companyID };
  }

  async createNewSurvey(): Promise<boolean> {
    const newSurvey = this.newSurveyService.createNewSurvey();
    if (this.selectedFolder) {
      newSurvey.folder = this.selectedFolder.id;
    }

    await this.saveSurveyService.saveUserSurveyToFirestore(
      newSurvey,
      this.userID
    );
    return await this.router.navigate([
      '/app/surveys/edit-survey',
      this.companyID,
      this.userID,
      newSurvey.id,
    ]);
  } // end createNewSurvey

  async deleteSurveyConfirm(survey: Survey): Promise<void> {
    const confirmDelete = await this.dialog
      .open(ConfirmDeleteSurveyDialogComponent, {
        data: { surveyName: survey.name },
      })
      .afterClosed()
      .toPromise();
    if (confirmDelete) {
      try {
        this.snackBar.open(`Deleting '${survey.name}'`, null, {
          duration: 5000,
        });

        await Promise.all([
          this.deleteSurveyService.deleteSurvey(this.userID, survey.id),
          this.deleteSurveyService
            .deleteSurveyFromFirestore(this.userID, survey.id)
            .catch((_error) => {
              /** Ignore firestore errors for now */
            }),
        ]);
        for (const index in this.userSurveys) {
          // Remove the survey from the local list
          if (this.userSurveys[index].id === survey.id) {
            this.userSurveys.splice(parseInt(index, 10), 1);
            this.changeDetector.detectChanges();
            break;
          }
        }
        this.snackBar.open(`Successfully deleted '${survey.name}'!`, null, {
          duration: 2000,
        });
      } catch (error) {
        console.error('Failed to delete survey', error);
        this.snackBar.open(
          `There was a problem deleting '${survey.name}'. Please try again.`,
          null,
          { duration: 2000 }
        );
      }
    }
  } // end delete survey

  async duplicateSurvey(surveyID: string): Promise<void> {
    const surveyCopy = this.duplicateSurveyService.duplicateSurvey(
      await this.loadSurveyService.getUserSurveyFromFirestore(
        this.userID,
        surveyID
      )
    );
    const originalName = surveyCopy.name;
    surveyCopy.name = 'Copy of ' + surveyCopy.name;
    await this.saveSurveyService.saveUserSurveyToFirestore(
      surveyCopy,
      this.userID
    );
    this.userSurveys = this.userSurveys
      .concat([surveyCopy])
      .sort((a, b) => a.name.localeCompare(b.name));
    this.snackBar.open(`Successfully duplicated '${originalName}'!`, null, {
      duration: 2000,
    });
  } // end duplicate survey func

  async copyOrgToYourSurveys(surveyID: string): Promise<void> {
    const surveyCopy = this.duplicateSurveyService.duplicateSurvey(
      await this.loadSurveyService.getUserSurveyFromFirestore(
        this.selectedDoctor.uid,
        surveyID
      )
    );
    // make sure not in folder b/c person copying wont have same folder
    delete surveyCopy.folder;
    await this.saveSurveyService.saveUserSurveyToFirestore(
      surveyCopy,
      this.userID
    );
    this.userSurveys = this.userSurveys
      .concat([surveyCopy])
      .sort((a, b) => a.name.localeCompare(b.name));
    this.snackBar.open(
      `Successfully copied '${surveyCopy.name}' to Your Surveys!`,
      null,
      { duration: 2000 }
    );
  }

  async copyToYourSurveys(surveyID: string): Promise<void> {
    const surveyCopy = this.duplicateSurveyService.duplicateSurvey(
      await this.loadSurveyService.getLibrarySurveyFromFirestore(surveyID)
    );
    // make sure not in folder b/c person copying wont have same folder
    delete surveyCopy.folder;
    await this.saveSurveyService.saveUserSurveyToFirestore(
      surveyCopy,
      this.userID
    );
    this.userSurveys = this.userSurveys
      .concat([surveyCopy])
      .sort((a, b) => a.name.localeCompare(b.name));
    this.snackBar.open(
      `Successfully copied '${surveyCopy.name}' to Your Surveys!`,
      null,
      { duration: 2000 }
    );
  } // end copyToYourSurveys

  onWindowResize(window: Window): void {
    this.showSideNav = window.innerWidth >= this.showSideNavWidth;
  }

  async addToFolder(survey: Survey, folder: SurveyFolder): Promise<void> {
    return this.saveSurveyService
      .updateSurveyFolder(this.userID, survey.id, folder.id)
      .then(() => {
        this.snackBar.open(
          `Successfully added '${survey.name}' to '${folder.name}'!`,
          null,
          { duration: 5000 }
        );
      });
  }

  setLocalSurveyToFolder(surveyId: string, folderId: string): void {
    this.userSurveys.find((survey) => survey.id === surveyId).folder = folderId;
  }

  putSurveyHome(event: any): void {
    this.showDragHome = false;
    console.log('Dropped data: ', event.item.data);
    this.setLocalSurveyToFolder(event.item.data.id, null);
    this.addToFolder(event.item.data, { id: null, name: 'My Questionnaires' });
  }

  onSurveyDrop(event: any): void {
    this.showDragHome = false;
    const folder = event.container.element.nativeElement.cdkDropData;
    const surveyData = event.item.data;
    this.setLocalSurveyToFolder(
      surveyData.id, // ID of dropped item data
      folder.id // ID of folder that was dropped on
    );
    this.addToFolder(surveyData, folder);
  }

  checkIfDraggingDisabled(): boolean {
    console.log(
      'Is dragging and dropping disabled? ',
      this.deviceService.isMobile()
    );
    if (this.deviceService.isMobile()) {
      return true;
    }

    return false;
  }

  async newFolder(): Promise<void> {
    return this.dialog
      .open(NewFolderDialogComponent, { width: '40vw' })
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result) {
          this.foldersService.setUserFolder(this.userID, {
            id: this.foldersService.generateRandomID(32),
            name: result,
            parent: this.selectedFolder ? this.selectedFolder.id : null,
          });
        }
      });
  }

  async deleteFolder(folder: SurveyFolder): Promise<void> {
    // Navigate to the previous folder if the current folder is deleted
    if (folder.id === this.selectedFolder.id) {
      await this.goToParentFolder();
    }

    // Delete subfolders in this folder
    await Promise.all(
      await this.userFolders$
        .pipe(take(1))
        .toPromise()
        .then((folders) =>
          folders
            .filter((subFolder) => subFolder.parent === folder.id)
            .map(this.deleteFolder)
        )
    );

    // Delete surveys in this folder
    for (let iSurvey = this.userSurveys.length - 1; iSurvey >= 0; iSurvey--) {
      if (this.userSurveys[iSurvey].folder === folder.id) {
        await Promise.all([
          this.deleteSurveyService.deleteSurveyFromFirestore(
            this.userID,
            this.userSurveys[iSurvey].id
          ),
          this.deleteSurveyService.deleteSurvey(
            this.userID,
            this.userSurveys[iSurvey].id
          ),
        ]);
        this.userSurveys.splice(iSurvey, 1);
      }
    }

    // Delete this folder
    await this.foldersService.deleteUserFolder(this.userID, folder.id);
  }

  goToFolder(folder: any, library?): void {
    this.searchValue = '';

    folder.library = library || false;
    this.selectedFolder = folder;
    // remove selectedsurvey from sidenav
    delete this.selectedSurvey;
  }

  resetVariables(): void {
    this.searchValue = '';
    delete this.selectedFolder;
    delete this.selectedSurvey;
  }

  async getFolderById(id: string): Promise<SurveyFolder> {
    return this.userFolders$
      .pipe(take(1))
      .toPromise()
      .then((folders) => folders.find((folder) => folder.id === id));
  }

  async goToParentFolder(): Promise<void> {
    if (this.selectedFolder.parent) {
      this.selectedFolder = await this.getFolderById(
        this.selectedFolder.parent
      );
    } else {
      this.resetVariables();
    }
  }

  async openMoveToFolderDialog(survey: Survey): Promise<void> {
    console.log(survey);
    return this.dialog
      .open(MoveToFolderDialogComponent, {
        width: '1000px',
        data: {
          folders: await this.userFolders$.pipe(take(1)).toPromise(),
        },
      })
      .afterClosed()
      .toPromise()
      .then((fid: string) => {
        if (fid) {
          if (fid === 'root') {
            fid = null;
          }

          console.log('fid is: ', fid);
          return this.saveSurveyService
            .updateSurveyFolder(this.userID, survey.id, fid)
            .then(() => {
              this.snackBar.open(`Successfully moved '${survey.name}'!`, null, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
              survey.folder = fid; // Change local model
            });
        }
        this.changeDetector.detectChanges(); // Necessary to refresh checkbox css classes
      });
  }

  async changeDoctor(): Promise<void> {
    const selectedDoctor = await this.dialog
      .open(SelectDoctorDialogComponent, {
        data: { doctorList: this.doctorList },
      })
      .afterClosed()
      .toPromise();
    if (selectedDoctor) {
      this.selectedDoctor = selectedDoctor;
      this.doctorSurveys = await this.loadSurveyService.getUserSurveyShortlistFromFirestore(
        this.selectedDoctor.uid
      );
    }
  }

  async getCompanyData(companyID: string): Promise<void> {
    try {
      const companyDataQuery: any = await this.apollo
        .query({
          query: this.companyDataQuery,
          variables: {
            companyID,
            token: await this.firebaseAuthService.getIdToken(),
          },
        })
        .toPromise();
      this.companyData = companyDataQuery.data.getCompanyData;
      console.log(this.companyData);
    } catch (err) {
      console.log(err);
    }
  }

  async getDoctorList(companyID: string): Promise<void> {
    try {
      const doctorListQuery: any = await this.apollo
        .query({
          query: this.doctorListQuery,
          variables: {
            companyID,
          },
        })
        .toPromise();
      let userList = doctorListQuery.data.getCompanyUserList;
      userList = userList.sort(this.sortByLastName);
      const doctorList = [];
      userList.forEach((user) => {
        user['fullName'] = user['first'] + ' ' + user['last'];
        doctorList.push(user);
      });
      this.doctorList = doctorList;
      console.log(this.doctorList);
    } catch (error) {
      console.log(error);
    }
  }

  sortByLastName(a: { last: string }, b: { last: string }): number {
    return a.last.localeCompare(b.last);
  }
}
