// @angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

// node_modules
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgDragDropModule } from 'ng-drag-drop';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GlobalPatientModule } from 'insig-app/global/patient/global-patient.module';
import { SurveysRoutingModule } from './surveys-routing.module';
import { InsigStepperModule } from 'insig-app/global/insig-stepper/insig-stepper.module';
import { PaymentsModule } from 'insig-app/global/payments/payments.module';

import { SurveysComponent } from './surveys.component';

import { ShowSurveysComponent } from './surveys/show-surveys.component';
import { ShowSurveysNavigationComponent } from './surveys/components/navigation/show-surveys-navigation.component';
import { ShowSurveysToolbarComponent } from './surveys/components/toolbar/show-surveys-toolbar.component';
import { ShowSurveysSidenavComponent } from './surveys/components/sidenav/show-surveys-sidenav.component';
import { ShowSurveysCheckInModeComponent } from './surveys/components/check-in-mode/show-surveys-check-in-mode.component';
import { SendSurveyDialogComponent } from './dialogs/send-survey-dialog.component';
import { IntegrationsDialogComponent } from './dialogs/integrations-dialog.component';

import { NewFolderDialogComponent } from './dialogs/new-folder-dialog/new-folder-dialog.component';
import { DeleteFolderDialogComponent } from './dialogs/delete-folder-dialog.component';

import { LaunchSurveyComponent } from './launch-survey/launch-survey.component';
import { LaunchSurveyLoginComponent } from './launch-survey-login/launch-survey-login.component';
import { ProviderSelectComponent } from './launch-survey/provider-select.component';
import { EditSurveyComponent } from './edit-survey/edit-survey.component';
import { EditElementComponent } from './edit-survey/edit-element/edit-element.component';
import { EditQuestionComponent } from './edit-survey/edit-question/edit-question.component';
import { SelectDoctorDialogComponent } from './dialogs/select-doctor-dialog.component';
import { SignatureTermsDialogComponent } from './dialogs/signature-terms-dialog.component';
import { ConfirmLaunchDialogComponent } from './dialogs/confirm-launch-dialog/confirm-launch-dialog.component';
import { ConfirmPreviewDialogComponent } from './dialogs/confirm-preview-dialog.component';
import { ConfirmDeleteSurveyDialogComponent } from './dialogs/confirm-delete-survey-dialog.component';
import { MoveToFolderDialogComponent } from './dialogs/move-to-folder-dialog.component';
import { DrawingDialogComponent } from './launch-survey/question-types/drawing/drawing-dialog.component';
import { SignatureDialogComponent } from './launch-survey/question-types/signature/signature-dialog.component';
import { PatientLoginComponent } from './launch-survey/patient-login.component';
import { SurveyPagesComponent } from './launch-survey/survey-pages.component';

import { QuestionTypesComponent } from './launch-survey/question-types/question-types.component';
import { MultiYesNoComponent } from './launch-survey/question-types/multiyesno/multiyesno.component';
import { YesNoComponent } from './launch-survey/question-types/yesno/yesno.component';
import { CheckboxComponent } from './launch-survey/question-types/checkbox/checkbox.component';
import { RadioComponent } from './launch-survey/question-types/radio/radio.component';
import { SelectComponent } from './launch-survey/question-types/select/select.component';
import { YearMonthDayComponent } from './launch-survey/question-types/yearmonthday/yearmonthday.component';
import { DateRangeComponent } from './launch-survey/question-types/daterange/daterange.component';
import { GridComponent } from './launch-survey/question-types/grid/grid.component';
import { SignatureComponent } from './launch-survey/question-types/signature/signature.component';
import { ShareNoteComponent } from './launch-survey/question-types/shareNote/shareNote.component';
import { PhotographComponent } from './launch-survey/question-types/photograph/photograph.component';
import { VideoComponent } from './launch-survey/question-types/video/video.component';
import { DoctorsComponent } from './launch-survey/question-types/doctors/doctors.component';
import { AutocompleteComponent } from './launch-survey/question-types/autocomplete/autocomplete.component';
import { MedicationsComponent } from './launch-survey/question-types/medications/medications.component';
import { MedicationAllergiesComponent } from './launch-survey/question-types/medication-allergies/medication-allergies.component';
import { PriorityComponent } from './launch-survey/question-types/priority/priority.component';
import { NumberComponent } from './launch-survey/question-types/number/number.component';
import { DrawingComponent } from './launch-survey/question-types/drawing/drawing.component';
import { PDFComponent } from './launch-survey/question-types/pdf/pdf.component';
import { PDFDialogComponent } from './launch-survey/question-types/pdf/pdf-dialog.component';
import { PDFTextFieldDialogComponent } from './launch-survey/question-types/pdf/pdf-textfield-dialog.component';
import { PhysicalGridComponent } from './launch-survey/question-types/physicalgrid/physicalgrid.component';
import { SurveyQuestionComponent } from './launch-survey/question-types/survey-question/survey-question.component';
import { DateQuestionComponent } from './launch-survey/question-types/date/date.component';
import { DiagramComponent } from './launch-survey/question-types/diagram/diagram.component';
import { BloodPressureComponent } from './launch-survey/question-types/blood-pressure/blood-pressure.component';
import { LinkComponent } from './launch-survey/question-types/link/link.component';

import { InputMasksModule } from '@insig-health/input-masks/input-masks.module';

import { HeightWeightEditComponent } from './edit-survey/question-types/height-weight/height-weight-edit.component';
import { VideoEditComponent } from './edit-survey/question-types/video/video-edit.component';
import { AddressEditComponent } from './edit-survey/question-types/address/address-edit.component';
import { AddressComponent } from './launch-survey/question-types/address/address.component';
import { ConditionsEditComponent } from './edit-survey/question-types/conditions/conditions-edit.component';
import { ConditionsComponent } from './launch-survey/question-types/conditions/conditions.component';
import { SurgeryEditComponent } from './edit-survey/question-types/surgery/surgery-edit.component';
import { SurgeryComponent } from './launch-survey/question-types/surgery/surgery.component';
import { YoutubeValidator } from './validators/youtube-validator.directive';
import { YoutubeEmbedPipe } from '../pipes/youtube-embed.pipe';
import { YoutubeEmbedContainerComponent } from './edit-survey/question-types/video/youtube-embed-container.component';
import { RadioCheckboxSelectEditComponent } from './edit-survey/question-types/radio-checkbox-select/radio-checkbox-select-edit.component';
import { ScoringEditComponent } from './edit-survey/question-types/scoring/scoring-edit.component';
import { MedicationsEditComponent } from './edit-survey/question-types/medications/medications-edit.component';
import { DoctorsEditComponent } from './edit-survey/question-types/doctors/doctors-edit.component';
import { AutocompleteEditComponent } from './edit-survey/question-types/autocomplete/autocomplete-edit.component';
import { FiveStarEditComponent } from './edit-survey/question-types/fivestar/fivestar-edit.component';
import { YearMonthDayEditComponent } from './edit-survey/question-types/yearmonthday/yearmonthday-edit.component';
import { PhotographEditComponent } from './edit-survey/question-types/photograph/photograph-edit.component';
import { SignatureEditComponent } from './edit-survey/question-types/signature/signature-edit.component';
import { RangeEditComponent } from './edit-survey/question-types/range/range-edit.component';
import { BloodPressureEditComponent } from './edit-survey/question-types/blood-pressure/blood-pressure-edit.component';
import { YesNoEditComponent } from './edit-survey/question-types/yesno/yesno-edit.component';
import { MultiYesNoEditComponent } from './edit-survey/question-types/multiyesno/multiyesno-edit.component';
import { PriorityEditComponent } from './edit-survey/question-types/priority/priority-edit.component';
import { NumberEditComponent } from './edit-survey/question-types/number/number-edit.component';
import { PhysicalGridEditComponent } from './edit-survey/question-types/physicalgrid/physicalgrid-edit.component';
import { DateEditComponent } from './edit-survey/question-types/date/date-edit.component';
import { PDFEditComponent } from './edit-survey/question-types/pdf/pdf-edit.component';
import { DrawingEditComponent } from './edit-survey/question-types/drawing/drawing-edit.component';
import { DrawingEditDialogComponent } from './edit-survey/question-types/drawing/drawing-edit-dialog.component';
import { TimeEditComponent } from './edit-survey/question-types/time/time-edit.component';
import { SurveyEditComponent } from './edit-survey/question-types/survey/survey-edit.component';
import { SurveySelectionDialogComponent } from './edit-survey/question-types/survey/survey-selection-dialog.component';
import { GridEditComponent } from './edit-survey/question-types/grid/grid-edit.component';
import { MedicationAllergiesEditComponent } from './edit-survey/question-types/medication-allergies/medication-allergies-edit.component';
import { LinkEditComponent } from './edit-survey/question-types/link/link-edit.component';
import { ShareNoteEditComponent } from './edit-survey/question-types/share-note/share-note-edit.component';
import { SendCommunicationEditComponent } from './edit-survey/question-types/send-communication/send-communication-edit.component';
import { BookAppointmentEditComponent } from './edit-survey/question-types/book-appointment/book-appointment-edit.component';
import { CollectPaymentEditComponent } from './edit-survey/question-types/collect-payment/collect-payment-edit.component';
import { CheckInModule } from 'insig-app/check-in/check-in.module';
import { TriggerDialogComponent } from './edit-survey/trigger-dialog.component';
import { QuestionTypesDialogComponent } from './edit-survey/question-types-dialog.component';
import { ConfirmLeaveEditDialogComponent } from './edit-survey/confirm-leave-edit-dialog.component';

import { KeyPipe } from './edit-survey/key-pipe.component';
import { DiagramEditComponent } from './edit-survey/question-types/diagram/diagram-edit.component';
import { MdSelectWithDialogDirective } from './edit-survey/directives/md-select-with-dialog/md-select-with-dialog.directive';
import { MdSelectDialogButtonComponent } from './edit-survey/directives/md-select-with-dialog/md-select-dialog-button.component';

import { MaterialComponentsModule } from '../material-components.module';

import { PipesModule } from '../pipes/pipes.module';
// import { PhonePipe } from '../pipes/phone.pipe';

import { InsigAppLoaderModule } from '../global/loader/loader.module';
import { InsigMaterialModule } from '../insig-material/insig-material.module';

import { InsigReferralModule } from 'insig-app/global/insig-referral/insig-referral.module';
import { BookAppointmentQuestionComponent } from './launch-survey/question-types/book-appointment-question/book-appointment-question.component';
import { CollectPaymentComponent } from './launch-survey/question-types/collect-payment/collect-payment.component';

// Libs
import { UiModule } from '@insig-health/ui';
import { GoogleMapsModule } from '@angular/google-maps';
import { AuthModule } from 'insig-app/auth/auth.module';
import { PatientTermsOfUseModule } from '@insig-health/patient-terms-of-use/patient-terms-of-use.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ClipboardModule,
    CKEditorModule,
    DpDatePickerModule,
    NgDragDropModule.forRoot(),
    NgxFileDropModule,
    PdfViewerModule,
    SurveysRoutingModule,
    MaterialComponentsModule,
    InputMasksModule,
    PipesModule,
    InsigAppLoaderModule,
    InsigMaterialModule,
    InsigStepperModule,
    GlobalPatientModule,
    CheckInModule,
    GoogleMapsModule,
    InsigReferralModule,
    PaymentsModule,
    UiModule,
    AuthModule,
    PatientTermsOfUseModule,
  ],
  exports: [
    SurveyPagesComponent,
    PatientLoginComponent,
    LaunchSurveyComponent,
    LaunchSurveyLoginComponent,
    ShowSurveysComponent,
    ShowSurveysNavigationComponent,
    ShowSurveysToolbarComponent,
    ShowSurveysSidenavComponent,
    ShowSurveysCheckInModeComponent,
    QuestionTypesComponent,
    PhotographComponent,
  ],
  declarations: [
    SurveysComponent,
    ShowSurveysComponent,
    ShowSurveysNavigationComponent,
    ShowSurveysToolbarComponent,
    ShowSurveysSidenavComponent,
    ShowSurveysCheckInModeComponent,
    LaunchSurveyComponent,
    LaunchSurveyLoginComponent,
    ProviderSelectComponent,
    EditSurveyComponent,
    EditElementComponent,
    EditQuestionComponent,
    SendSurveyDialogComponent,
    IntegrationsDialogComponent,
    SelectDoctorDialogComponent,
    SignatureTermsDialogComponent,
    ConfirmLaunchDialogComponent,
    PatientLoginComponent,
    SurveyPagesComponent,
    ConfirmPreviewDialogComponent,
    ConfirmDeleteSurveyDialogComponent,
    DrawingDialogComponent,
    SignatureDialogComponent,
    NewFolderDialogComponent,
    MoveToFolderDialogComponent,
    // question types stuff
    AddressEditComponent,
    AddressComponent,
    ConditionsEditComponent,
    ConditionsComponent,
    SurgeryComponent,
    SurgeryEditComponent,
    QuestionTypesComponent,
    MultiYesNoComponent,
    YesNoComponent,
    GridComponent,
    YearMonthDayComponent,
    SignatureComponent,
    ShareNoteComponent,
    PhotographComponent,
    NumberComponent,
    MedicationAllergiesComponent,
    VideoComponent,
    DoctorsComponent,
    AutocompleteComponent,
    PhysicalGridComponent,
    MedicationsComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    DateRangeComponent,
    PriorityComponent,
    DrawingComponent,
    PDFComponent,
    PDFDialogComponent,
    PDFTextFieldDialogComponent,
    SurveyQuestionComponent,
    DateQuestionComponent,
    DiagramComponent,
    BloodPressureComponent,
    VideoEditComponent,
    LinkEditComponent,
    LinkComponent,
    ShareNoteEditComponent,
    YoutubeValidator,
    YoutubeEmbedPipe,
    MedicationAllergiesEditComponent,
    YoutubeEmbedContainerComponent,
    RadioCheckboxSelectEditComponent,
    ScoringEditComponent,
    MedicationsEditComponent,
    HeightWeightEditComponent,
    DoctorsEditComponent,
    AutocompleteEditComponent,
    FiveStarEditComponent,
    YearMonthDayEditComponent,
    PhotographEditComponent,
    SignatureEditComponent,
    RangeEditComponent,
    BloodPressureEditComponent,
    YesNoEditComponent,
    MultiYesNoEditComponent,
    PriorityEditComponent,
    NumberEditComponent,
    PhysicalGridEditComponent,
    DateEditComponent,
    PDFEditComponent,
    DrawingEditComponent,
    DrawingEditDialogComponent,
    TimeEditComponent,
    SurveyEditComponent,
    SurveySelectionDialogComponent,
    GridEditComponent,
    SendCommunicationEditComponent,
    BookAppointmentEditComponent,
    CollectPaymentEditComponent,
    TriggerDialogComponent,
    QuestionTypesDialogComponent,
    KeyPipe,
    DiagramEditComponent,
    MdSelectWithDialogDirective,
    MdSelectDialogButtonComponent,
    DeleteFolderDialogComponent,
    ConfirmLeaveEditDialogComponent,
    BookAppointmentQuestionComponent,
    CollectPaymentComponent,
  ],
})
export class SurveysModule { }
