<div class="p-3" style="max-width:300px;">
  <!-- #region tab buttons -->
  <button
    mat-button
    class="w-100 justify-content-start"
    [ngClass]="{
      'button-item-selected': selectedTab === 'library',
      'primary-color-text': selectedTab === 'library'
    }"
    (click)="changeTab('library')"
  >
    <mat-icon class="notranslate">local_library</mat-icon>
    <span class="ml-4">Library</span>
  </button>

  <button
    mat-button
    class="w-100 justify-content-start"
    [ngClass]="{
      'button-item-selected': selectedTab === 'user',
      'primary-color-text': selectedTab === 'user'
    }"
    (click)="changeTab('user')"
  >
    <mat-icon class="notranslate">person</mat-icon>
    <span class="ml-4">My Questionnaires</span>
  </button>

  <button
    mat-button
    class="w-100 justify-content-start"
    [ngClass]="{
      'button-item-selected': selectedTab === 'organization',
      'primary-color-text': selectedTab === 'organization'
    }"
    (click)="changeTab('organization')"
  >
    <mat-icon class="notranslate">people</mat-icon>
    <span class="ml-4">Group</span>
  </button>
  <!-- #endregion tab buttons -->

  <!-- #region tablet mode button -->
  <button
    mat-button
    class="mt-2 w-100 justify-content-start primary-color-full"
    *ngIf="!!companyData?.checkIn"
    (click)="changeTab('checkin')"
  >
    <mat-icon class="notranslate">check_circle</mat-icon>
    <span class="ml-4">Check-In Mode</span>
  </button>
  <!-- #endregion tablet mode button -->

  <div class="divider divider-grey" @fadeOut *ngIf="!showSpacing"></div>
  <div class="height10px" @fadeOut *ngIf="!showSpacing"></div>

  <div @fadeOut *ngIf="!showSpacing">
    <!-- #region selected folder menu -->
    <div *ngIf="selectedFolder && !selectedSurvey">
      <!-- #region folder name label -->
      <div class="w-100 text-center">
        <h5 class="d-flex flex-row align-items-center justify-content-center">
          <mat-icon class="notranslate">folder</mat-icon>
          <span>&nbsp;{{ selectedFolder.name }}</span>
        </h5>
      </div>
      <!-- #endregion folder name label -->

      <!-- #region renaming folder button -->
      <button
        mat-raised-button
        class="primary-color-text w-100 my-1"
        *ngIf="!selectedFolder.library"
      >
        <button
          mat-button
          class="d-flex flex-row align-items-center justify-content-center h-100 w-100 renamingButtonLabel"
          *ngIf="!renamingFolder"
          (click)="renamingFolder = true; renamingInput.focus()"
        >
          <mat-icon class="notranslate">mode_edit</mat-icon>
          <span>&nbsp;Rename Folder</span>
        </button>

        <div class="d-flex flex-row align-items-center">
          <span>&nbsp;</span
          ><!-- vertical spacer -->
          <input
            #renamingInput
            class="flex noBorder"
            [(ngModel)]="selectedFolder.name"
            (blur)="renamingFolder = false; saveFolder(selectedFolder)"
            (keydown.enter)="renamingFolder = false"
          />
          <mat-icon
            class="notranslate"
            (click)="
              renamingFolder = false;
              saveFolder(selectedFolder);
              $event.stopPropagation()
            "
            >save</mat-icon
          >
        </div>
      </button>
      <!-- #endregion renaming folder button -->

      <!-- #region delete folder button -->
      <button
        *ngIf="!selectedFolder.library"
        mat-raised-button
        class="primary-color-text w-100 my-1"
        (click)="openDeleteFolderDialog()"
      >
        <mat-icon class="notranslate">delete</mat-icon>
        <span>&nbsp;Delete Folder</span>
      </button>
      <!-- #endregion delete folder button -->
    </div>
    <!-- #endregion selected folder menu -->

    <!-- #region selected survey menu -->
    <div *ngIf="selectedSurvey">
      <div class="w-100 text-center">
        <h5>{{ selectedSurvey.name }}</h5>
      </div>

      <button
        mat-raised-button
        class="w-100 my-1"
        (click)="onEditClicked.emit(selectedSurvey)"
      >
        <mat-icon class="notranslate">mode_edit</mat-icon>
        <span>&nbsp;Edit</span>
      </button>

      <button
        mat-raised-button
        class="w-100 my-1"
        (click)="onPatientPreviewClicked.emit(selectedSurvey)"
      >
        <mat-icon class="notranslate">desktop_windows</mat-icon>
        <span>&nbsp;Patient Preview</span>
      </button>
    </div>
    <!-- #endregion selected survey menu -->
  </div>

  <hr />

  <!-- <div class="mt-4 card p-2">
    <h6 class="bold text-center">Integrate with your EMR!</h6>
    <p>
      Download our EMR Integration application to seamlessly import Insig notes
      in your EMR.
      <a class="d-inline" href="https://desktop.insighealth.com/"
        >Click here to Download - EMR Integration App</a
      >.
    </p>
  </div> -->

</div>
